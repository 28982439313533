<template>
	<div class="container flex wrap f-justifyB" @click="$refs.inputRef.focus()">
		<div class="index-box blue-bg" @click.stop="userCunHandle">
			<span>存衣</span>
			<span>Store shoes</span>
		</div>
		<div class="index-box red-bg" @click.stop="userQuHandle">
			<span>取衣</span>
			<span>Take shoes</span>
		</div>
		<div class="index-box yellow-bg" @click.stop="$router.push('./adminLogin')">
			<span>管理员</span>
			<span>Administrator</span>
		</div>
		<div class="index-box green-bg" @click.stop="tipHandle">
			<span>操作说明</span>
			<span>explain</span>
		</div>
		<div class="bottom-gzbh flex f-column">
			<span class="flex">机柜地址：{{address}}</span>
			<div class="flex">
				<span>机柜编号：{{gzbh}}</span>
				<span class="ml20">客服电话：{{phone}}</span>
			</div>
		</div>

		<input ref="inputRef" class="input-ref" v-model="str" />

		<van-dialog v-model="show" width="90%" :showConfirmButton="false">
			<div class="flex f-column f-align dialog-top">
				<div class="flex f-column f-align">
					<div class="header">下单方式1</div>
					<span class="font24 blue">(衣物带在智能柜旁)</span>
				</div>
				<span class="font36 mt30">1.点击柜体屏幕存衣--微信扫屏幕二维码</span>
				<img src="../assets/image/jiantou.png" class="jian-tou">
				<span class="font36">2.进入小程序--选择需要服务的项目并支付--弹开柜门--放入脏衣--关门离开。</span>

				<div class="flex f-column f-align">
					<div class="header">下单方式2</div>
					<span class="font24 blue">(衣物不在智能柜旁)</span>
				</div>
				<span class="font36 mt30">进入小程序--点击预约下单--选择服务项目--填写地址及上门时间--支付完成--待上门人员收取衣物 </span>

        <div class="flex f-column f-align">
          <div class="header">下单方式3</div>
          <span class="font24 blue">(衣物不在智能柜旁)</span>
        </div>
        <span class="font36 mt30">进入小程序--点击立即下单找到离您最近的智能柜--选择需要服务的项目并支付--下单后生成开箱码--带上衣物后使用开箱二维码在柜体识别开门--放入衣物关门离开。</span>


      </div>
			<div class="dialog-bottom" @click="tipBack">返回({{tipTimeNum}}s)</div>
		</van-dialog>
	</div>
</template>

<script>
	import {
		arrayEqual
	} from '../utils/tool.js'
	export default {
		props: {
			gzbh: String,
			phone: String,
			address: String
		},
		data() {
			return {
				show: false,
				str: '',
				advTimeNum: 0,
				timer: '', // 外屏广告120s进入
				isScan: true, // 默认可以扫描
				tipTimeNum: 60,
				tipTimer: null
			}
		},
		mounted() {
			this.$emit('change', 'index')
			this.timer = setInterval(() => {
				this.advTimeNum++
				if (this.advTimeNum == 120) {
					let advertWai = JSON.parse(localStorage.getItem('advertWai')) || []
					if (advertWai.length > 0) {
						this.$router.replace('/advertList')
						clearInterval(this.timer)
					} else {
						this.advTimeNum = 0
					}
				}
			}, 1000)

			this.$refs.inputRef.focus()
			this.onkeyDownHandle()
		},
		beforeDestroy() {
			clearInterval(this.timer)
			document.onkeydown = undefined
		},
		methods: {
			tipBack() {
				this.show = false
				clearInterval(this.tipTimer)
			},
			tipHandle() {
				this.show = true
				this.tipTimeNum = 60
				this.tipTimer = setInterval(() => {
					this.tipTimeNum--
					if (this.tipTimeNum == 0) {
						this.show = false
						clearInterval(this.tipTimer)
					}
				}, 1000)
			},
			userCunHandle() {
				localStorage.setItem('type', 'user-cun')
				this.$router.push('./scanCode')
			},
			userQuHandle() {
				localStorage.setItem('type', 'user-qu')
				this.$router.push('./quCode?type=userqu')
			},
			quCodeHandle() {
				this.$post("/wxPay/payUrl", {
					takeOutCode: this.str,
					gzbh: localStorage.getItem('gzbh')
				}).then(res => {
					this.str = ''
					if (res.code == 200) {
						localStorage.setItem('type', 'user-qu')
						if (res.codeUrl) {
							localStorage.setItem('xmbh', res.xmbh)
							localStorage.setItem('openCmd', res.openCmd)
							localStorage.setItem('portName', res.portName)
							this.$router.push('./payMoney?code=' + res.codeUrl + '&price=' + res.price +
								'&outTradeNo=' + res.outTradeNo)
						} else {
							localStorage.setItem('xmbh', res.xmbh)
							localStorage.setItem('openCmd', res.openCmd)
							localStorage.setItem('portName', res.portName)
							this.$router.push('./boxDoorOpen')
						}
					} else {
						this.isScan = true
						this.$toast({
							message: res.msg,
						})
					}
				}).catch(() => {
					this.isScan = true
				})
			},
			onkeyDownHandle() {
				let _this = this
				document.onkeydown = function(e) {
					if (_this.$route.path == '/index') {
						let key = e.keyCode
						if (key == 13) {
							if (_this.str == '') return;
							if (!_this.isScan) return;
							_this.isScan = false
							console.log(_this.str);
							_this.$post('/Order/deposit', {
								saveCode: _this.str,
								gzbh: localStorage.getItem('gzbh')
							}).then((res) => {
								if (res.code == 200) {
									localStorage.setItem('type', 'user-cun')
									localStorage.setItem('xmbh', res.xmbh)
									localStorage.setItem('openCmd', res.openCmd)
									localStorage.setItem('portName', res.portName)
									_this.$router.push('./boxDoorOpen')
								} else if (res.code == 202) {
									_this.quCodeHandle()
								} else {
									_this.str == ''
									_this.$toast({
										message: res.msg
									})
									_this.isScan = true
								}
							}).catch(() => {
								_this.isScan = true
							})
						}
					}
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.container {
		height: 710px;
		padding: 54px 40px;
		.index-box {
			width: 440px;
			height: 280px;
			border-radius: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			span:first-child {
				font-size: 60px;
				line-height: 84px;
				color: #FFFFFF;
			}

			span:last-child {
				font-size: 40px;
				line-height: 56px;
				color: #FFFFFF;
			}
		}

		.blue-bg {
			background: linear-gradient(270deg, #4E8CFF 0%, #216FFF 100%);
		}

		.red-bg {
			background: linear-gradient(270deg, #FF377F 0%, #FF146A 100%);
		}

		.yellow-bg {
			background: linear-gradient(270deg, #FE9244 0%, #FB7818 100%);
			margin-top: 40px;
		}

		.green-bg {
			background: linear-gradient(270deg, #36C5BC 0%, #0AA69C 100%);
			margin-top: 40px;
		}

		.input-ref {
			position: fixed;
			left: -1000px;
			top: 0;
		}
	}

	::v-deep .van-dialog {
		background-color: rgba(0, 0, 0, 0);
		border-radius: 0;

		.van-dialog__content {
			border-radius: 50px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.dialog-top {
				width: 100%;
				background-color: #ffffff;
				border-radius: 50px;
				padding: 0 50px 90px;

				.font36 {
					line-height: 50px;
					text-align: center;
				}
			}

			.header {
				font-size: 50px;
				line-height: 70px !important;
				padding-top: 60px;
				position: relative;
				color: #000000;
				font-weight: bold;
				background-color: #ffffff;
				border-radius: 50px 50px 0 0;
			}

			.jian-tou {
				width: 48px;
				height: 48px;
				margin: 10px 0;
			}

			.dialog-bottom {
				width: 250px;
				height: 80px;
				background: #FFFFFF;
				border-radius: 6px;
				font-size: 36px;
				font-weight: 800;
				color: #333333;
				line-height: 80px;
				text-align: center;
				margin-top: 75px;
			}
		}
	}
</style>
